import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthService } from '../../services/auth.services';
import { StorageService } from '../../services/storage.service';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
    @ViewChild('username') username: ElementRef;

    loginForm: FormGroup;
    loading = false;
    submitted = false;
    error = '';
    hide: boolean = true;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private auth: AuthService,
        private storage: StorageService,
        private notifier: NotifierService,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {
        // redirect to home if already logged in
        if (this.storage.getToken()) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    ngAfterViewInit() {
        this.username.nativeElement.focus();
        this.changeDetectorRef.detectChanges();
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.auth.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe({
                next: (data) => {
                    this.storage.saveUser(data);
                    this.router.navigate(['/']);
                },
                error: error => {
                    if (error.statusText == 'Unknown Error') {
                        this.notifier.showError('Errore', 'Si è verificato un errore imprevisto.');
                    } else {
                        this.notifier.showError('Errore', 'Credenziali non valide.');
                        // this.error = JSON.stringify(error);
                    }
                    this.loading = false;
                }
            });
    }
}