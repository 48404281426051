<div class="container" style="margin-top: 36px;">
    <div class="home-image-container">
        <img src="assets/img/sfondo-home.png" alt="Background" class="home-background-image">
        <h1 class="home-image-text">MY ITINERA</h1>
    </div>
</div>

<div class="container" style="margin-top: 48px;">
  <div class="home-grid">
    <div class="home-item" [routerLink]="'/presenze'">
      <img src="assets/img/Icona Presenze.svg" alt="Presenze" class="home-icon">
      <span class="home-text">Presenze</span>
    </div>
    <div class="home-item" [routerLink]="'/assenze'" *ngIf="['Partinta Iva', 'Occasionale'].indexOf(contract) == -1">
      <img src="assets/img/Icona Assenze.svg" alt="Assenze" class="home-icon">
      <span class="home-text">Assenze</span>
    </div>
    <div class="home-item" [routerLink]="'/segnalazioni'">
      <img src="assets/img/Icona Segnalazioni.svg" alt="Segnalazioni" class="home-icon">
      <span class="home-text">Segnalazioni</span>
    </div>
    <div class="home-item" [routerLink]="'/statistiche'">
      <img src="assets/img/Icona Statistiche.svg" alt="Statistiche" class="home-icon">
      <span class="home-text">Statistiche</span>
    </div>
  </div>
</div>