import { ActivityModel, NetworkModel, SchoolModel, ContractModel, StudentsModel, UsersModel, AssistanceModel, AssistanceFullModel, RequestModel, AbsenceModel, YearModel, AbsenceFullModel } from "./db.model";

export class OptionModel {
    label: string = ''; // nome dell'entità

    title?: string = ''; // titolo della pagina (label presente solo se stringa non vuota)
    detailTitle?: string = ''; // titolo della pagina di dettaglio (label presente solo se stringa non vuota)
    titleClass?: string = ''; // stringa che viene aggiunta alla classe del titolo

    tableClass?: string = ''; // stringa che viene aggiunta alla classe del titolo

    responsive?: boolean = true; // abilita lo scroll orizzontale

    sorting?: boolean = true; // abilita la possibilità di ordinare tramite header della tabella

    filterAll?: boolean = true; // mostra/nasconde il campo di ricerca libera
    filterHeader?: boolean = true; // attiva/disattiva i filtri per singolo campo nel menu di destra

    pagination?: boolean = true; // abilita la funzionalità di paginazione
    pageSizeOptions?: number[] = [10, 100]; // definisce i possibili valori degli elementi per pagina
    pageSize?: number = 10; // valore di default per gli elementi per pagina

    rowClick?: 'edit' | 'detail' | 'info' = 'detail'; // definisce l'azione del click sulla riga della tabella
    // edit -> apre il popup di modifica
    // detail -> reindirizza alla pagina di dettaglio
    // info -> mostra una sezione di informazioni aggiuntive con tutti i campi disponnibili

    rowActionMenu?: boolean = true; // abilita il menu di riga
    rowActionDetail?: boolean = true; // abilita il link al dettaglio nel menu di riga
    rowActionEdit?: boolean = true; // abilita il link al popup di modifica nel menu di riga

    exportTypes?: ('xlsx' | 'pdf' | 'json' | 'xml' | 'csv' | 'xlsx_full')[] = []; // abilita il menu di download e le singole voci

    defaultSortActive?: string; // campo su cui effettuare ordinamento di default
    defaultSortDirection?: ('asc' | 'desc'); // direzione di default per l'ordinamento
    defaultClientFilter?: FiltersModel[]; // set di filtri applicati lato client di default - aggiunti ai filtri laterali
}

export class FormUpSelectModel {
    upField: string; // nome del campo del padre da usare come chiave
    filterKey: string; // nome del campo del figlio da usare come chiave
}

export class FormSelectModel {
    sourceName: string; // sostituire con lista tabelle gestite
    placeholder?: string;
    id: string;// nome del campo da usare come chiave
    label: string;// nome del campo da usare come label all'utente
    filters: FiltersModel[];
    sort?: SortModel[]; // [{ field, direction }]
    distinct?: boolean;
    filter?: boolean; // abilita l'input per filtrare le occorrenze della select
    upSelectFilter?: FormUpSelectModel; // select collegata usata per filtrare i valori
    upsertId?: string; // nome del campo da usare come chiave per l'export - se null, viene usato 'label'
}

export class FormFieldModel {
    type: 'select' | 'hidden' | 'text' | 'textarea' | 'boolean' | 'number' | 'currency' | 'readonly' | 'date' | 'time' | 'week' | null;
    name: string;
    defaultValue?: string | number;
    placeholder?: string;
    isRequired?: boolean;
    conditionalRequired?: string; // valorizzato con il name di un altro campo per definire un'obbligatorietà condizionata
    minLength?: number;
    maxLength?: number;
    pattern?: string;
    disabled?: boolean;
    customValidation?: 'codiceFiscale' | null;
    select?: FormSelectModel;
    formSort?: number = 0;
}

export class FieldsModel {
    name: string;
    label: string;
    form?: FormFieldModel;
    format: 'string' | 'number' | 'currency' | 'date' | 'datetime' | 'time' | 'duration' | 'boolean' | 'hidden';
    crud: ('select' | 'insert' | 'update' | 'upsert')[];
    tableSort?: number = 0; // ordinamento dei campi nella tabella,
    show?: boolean = true; // mostra/nasconte le colonne
    group?: string; // utilizzato per raggruppare i dati nelle form e nel detail
    secret?: boolean; // se valorizzato, il campo non viene mai richiesto in select (usato ad esempio per le password)
    linkText?: string; // link a cui viene fatto click per visualizzare il dettaglio
    linkItemField?: string; // campo da usare per il link
}

export class FiltersModel {
    field: string;
    value: string | number | 'LAST_MONTH';
    operator: "=" | "!=" | ">" | ">=" | "<" | "<=" | "like";
}

export class SortModel {
    field: string;
    direction: 'asc' | 'desc';
}

export class LinkedModel {
    title: string; // usato per il titolo dell'entità
    sourceName: string; // nome dell'entità collegata (tabella di relazione in caso di "n a m")
    routerLink: string; // url path
    routerId: string; // parametro dell'oggetto da inserire nella url
    idJoin: string; // chiave da confrontare con l'id input della pagina
    fields: FieldsModel[]; // elenco dei campi da mostrare a video per l'entità collegata
    filters: FiltersModel[]; // filtri dell'entità collegata
    select?: FormSelectModel; // entità collegata nel caso di relazione "n a m",
    addFields?: FieldsModel[]; // dettaglio dei campi per l'inserimento libero
}

// usato per una lista di selezione delle entità key-value
export class EntitySelectModel {
    value: EntityModel['sourceName'];
    label: EntityModel['options']['label'];
}

export class EntityModel {
    model: any;
    // model: ( typeof ActivityModel | typeof NetworkModel | typeof SchoolModel | typeof ContractModel | typeof StudentsModel | typeof UsersModel | typeof AssistanceModel | typeof RequestModel );
    sourceName: ('activities' | 'absences' | 'absences_full' | 'networks' | 'schools' | 'contracts' | 'students' | 'users' | 'assistances' | 'assistances_full' | 'requests' | 'years');
    fields: FieldsModel[];
    filters: FiltersModel[];
    options: OptionModel;
    linked: LinkedModel[];

    constructor(source: string) {
        // in caso di inizializzazione vuota, restituisco null
        if (source == '') { return null; }

        // const activeList: ListPageModel = this.getActiveList(source);
        const activeList: EntityModel = entities[source];
        /*
        switch (source) {
            case 'networks': {
                this.model       = networkList.model;
                this.sourceName  = networkList.sourceName;
                this.fields      = networkList.fields;
                this.filters     = networkList.filters;
                this.options     = networkList.options;
                break;
            }
            case 'schools': {
                this.model       = schoolList.model;
                this.sourceName  = schoolList.sourceName;
                this.fields      = schoolList.fields;
                this.filters     = schoolList.filters;
                this.options     = schoolList.options;
                break;
            }
            case 'activities': {
                this.model       = activityList.model;
                this.sourceName  = activityList.sourceName;
                this.fields      = activityList.fields;
                this.filters     = activityList.filters;
                this.options     = activityList.options;
                break;
            }
            default: {
                break;
            }
        }
        */

        this.model = activeList.model;
        this.sourceName = activeList.sourceName;
        // inizializzo tutti i campi come visibili, se non definito il valore "show"
        this.fields = activeList.fields.map(f => {
            if (f.show == undefined) {
                f.show = true;
            }
            return f;
        });
        this.filters = activeList.filters;
        this.options = activeList.options;
        this.linked = activeList.linked;
    }

    private _getActiveList(source: string): EntityModel {
        switch (source) {
            case 'networks': {
                return networkList;
            }
            case 'schools': {
                return schoolList;
            }
            case 'activities': {
                return activityList;
            }
            case 'contracts': {
                return contractList;
            }
            case 'students': {
                return studentList;
            }
            case 'users': {
                return userList;
            }
            case 'assistances': {
                return assistanceList;
            }
            case 'requests': {
                return requestList;
            }
            case 'absences': {
                return absenceList;
            }
            default: {
                return null;
            }
        }
    }

    getOptions(): OptionModel {
        return this.options;
    }

    getFields(): FieldsModel[] {
        return this.fields
            .slice()
            .sort(function (a, b) { return a.tableSort - b.tableSort; });
    }

    getLinked(): LinkedModel[] {
        return this.linked;
    }

    getFieldsList(): string[] {
        return this.fields
            .slice()
            .filter(f => f.crud.indexOf('select') > -1)
            .sort(function (a, b) { return a.tableSort - b.tableSort; })
            .map(field => {
                return field.name;
            });
    }

    getFieldsGroups(): string[] {
        const list = this.getFields()
            .map(f => f.group);
        return list.filter((value, index) => list.indexOf(value) === index);
    }

    getFormFields(): FieldsModel[] {
        return this.fields
            .slice()
            .filter(f => f.form != null)
            .sort(function (a, b) { return a.form.formSort - b.form.formSort; });
    }

    getAllFieldsList(): string[] {
        return this.fields
            .slice()
            .filter(f => f.secret != true)
            .filter(f => f.crud.indexOf('select') > -1
                || (f.crud.indexOf('update') > -1 && f.form != null))
            .map(field => {
                return field.name;
            });
    }

    getAllFieldsKV(): FieldsModel[] {
        let output: FieldsModel[] = [];
        this.fields
            .slice()
            .sort(function (a, b) { return a.tableSort - b.tableSort; })
            .forEach(field => {
                // trasposizione chiave-valore della lista dei campi
                output[field.name] = field;
            });
        return output;
    }

    getFormFieldsGroups(): string[] {
        const list = this.getFormFields()
            .map(f => f.group);
        return list.filter((value, index) => list.indexOf(value) === index);
    }

    getGroupFormFields(groupName, crud): FieldsModel[] {
        return this.getFormFields()
            .slice()
            .filter(f => f.form.type != 'hidden')
            // vengono esclusi i campi readoly nel caso di form di insert (quelli generati automaticamente dal sistema)
            .filter(f => f.crud.indexOf(crud) > -1 && !(crud == 'insert' && f.form.type == 'readonly'))
            .filter(f => f.group == groupName);
    }

    getVisibleFields(): FieldsModel[] {
        return this.fields
            .slice()
            .filter(f => f.format != 'hidden')
            .filter(f => f.crud.indexOf('select') > -1)
            .sort(function (a, b) { return a.tableSort - b.tableSort; });
    }

    getGroupFields(groupName): FieldsModel[] {
        return this.getVisibleFields()
            .filter(f => f.group == groupName);
    }

    getVisibleFieldsList(): string[] {
        return this.fields
            .slice()
            .filter(field => field.format != 'hidden')
            .filter(f => f.crud.indexOf('select') > -1)
            .sort(function (a, b) { return a.tableSort - b.tableSort; })
            .map(f => f.name);
    }

    getVisibleFieldsKV(): FieldsModel[] {
        let output: FieldsModel[] = [];
        this.fields
            .slice()
            .filter(f => f.crud.indexOf('select') > -1)
            .sort(function (a, b) { return a.tableSort - b.tableSort; })
            .forEach(field => {
                // trasposizione chiave-valore della lista dei campi
                output[field.name] = field;
            });
        return output;
    }

    getFormSelectList(): string[] {
        return this.fields
            .slice()
            .filter(f => f.form?.type == 'select')
            .filter(f => f.crud.indexOf('insert') > -1 || f.crud.indexOf('update') > -1)
            .map(field => {
                return field.name;
            });
    }

    getFormSelectByFieldName(fieldName: string): FormFieldModel {
        return this.fields
            .slice()
            .filter(f => f.name == fieldName)
            .map(field => {
                return field.form;
            })[0];
    }

    getUpsertEntites(): EntitySelectModel[] {
        let upsertEntitiesList: EntitySelectModel[] = [];
        // usa Object.values() perché la chiave della lista è non numerica
        Object.values(entities).forEach(entity => {
            let isUpsertEntity = false;
            entity.fields.forEach(field => {
                if (field.crud.indexOf('upsert') != -1) {
                    // trovato campo con upsert
                    isUpsertEntity = true;
                }
            });
            if (isUpsertEntity) {
                // ha trovato almeno un campo con upsert
                upsertEntitiesList.push({ 'value': entity.sourceName, 'label': entity.options.label });
            }
        });
        return upsertEntitiesList;
    }

    getUpsertFieldsByEntityName(entityName: EntityModel["sourceName"]): FieldsModel[] {
        return entities[entityName].fields.slice()
            .filter(f => f.crud.indexOf('upsert') != -1)
            .sort(function (a, b) { return a.label.localeCompare(b.label); });
    }

    getUpdateFieldsByEntityName(entityName: EntityModel["sourceName"]): FieldsModel[] {
        return entities[entityName].fields.slice()
            .filter(f => f.form?.type != 'hidden')
            .filter(f => f.crud.indexOf('update') != -1)
            .sort(function (a, b) { return a.label.localeCompare(b.label); });
    }

    getUpdateRequiredFieldsByEntityName(entityName: EntityModel["sourceName"]): FieldsModel[] {
        return entities[entityName].fields.slice()
            .filter(f => f.form?.type != 'hidden')
            .filter(f => f.crud.indexOf('update') != -1)
            .filter(f => f.form.isRequired)
            .sort(function (a, b) { return a.label.localeCompare(b.label); });
    }

    getRequiredConditionsNames(): string[] {
        var output = [];
        this.fields
            .slice()
            .filter(f => f.form?.conditionalRequired?.length > 0)
            .forEach(f => {
                if (output[f.form.conditionalRequired] == undefined) {
                    output[f.form.conditionalRequired] = [];
                }
                output[f.form.conditionalRequired].push(f.form.name);
            });

        return output;
    }

    getFieldFormFormat(fieldName: string): FormFieldModel["type"] {
        return this.fields
            .slice()
            .filter(f => f.name == fieldName)
            .map(field => {
                return field.form?.type;
            })[0];
    }

    getFieldByName(fieldName: string): FieldsModel {
        return this.fields
            .slice()
            .filter(f => f.name == fieldName)[0];
    }
}

const networkList = new EntityModel('');
networkList.model = typeof NetworkModel;
networkList.sourceName = 'networks';
networkList.fields = [
    { name: 'id', label: 'ID', form: { type: 'hidden', name: 'id', pattern: "^[0-9]*$" }, format: 'hidden', crud: ['select', 'update'] },
    {
        name: 'description', label: 'Nome', format: 'string', crud: ['select', 'insert', 'update'], tableSort: 1,
        form: { type: 'text', name: 'description', placeholder: 'Inserisci il nome della rete', isRequired: true, formSort: 1, pattern: "", maxLength: 45 }
    },
    {
        name: 'contract_start', label: 'Data inizio contratto', group: 'Contratto', format: 'date', tableSort: 2, crud: ['select', 'insert', 'update'],
        form: { name: 'contract_start', type: 'date', isRequired: true, formSort: 2 }
    },
    {
        name: 'contract_end', label: 'Data fine contratto', group: 'Contratto', format: 'date', tableSort: 3, crud: ['select', 'insert', 'update'],
        form: { name: 'contract_end', type: 'date', isRequired: true, formSort: 3 }
    },
    {
        name: 'mono', label: 'Istituto singolo', format: 'boolean', tableSort: 4, crud: ['select', 'insert', 'update'],
        form: { name: 'mono', type: 'boolean', isRequired: true, defaultValue: 0, formSort: 4 }
    },
    {
        name: 'hours', label: 'N. Ore Contratto', group: 'Contratto', format: 'number', tableSort: 5, crud: ["select", "insert", "update"],
        form: { name: 'hours', type: 'number', isRequired: false, formSort: 5 }
    },
    {
        name: 'hourly_rate', label: 'Tariffa oraria', group: 'Contratto', format: 'currency', tableSort: 6, show: false, crud: ["select", "insert", "update"],
        form: { name: 'hourly_rate', type: 'currency', isRequired: false, formSort: 6 }
    },
    {
        name: 'hourly_rate_vat', label: 'Totale valore', group: 'Contratto', format: 'currency', tableSort: 7, show: false, crud: ["select", "insert", "update"],
        form: { name: 'hourly_rate_vat', type: 'currency', isRequired: false, formSort: 7 }
    },
    {
        name: 'hourly_rate_gross', label: 'Tariffa oraria IVA', group: 'Contratto', format: 'currency', tableSort: 8, show: false, crud: ["select", "insert", "update"],
        form: { name: 'hourly_rate_gross', type: 'currency', isRequired: false, formSort: 8 }
    },
    {
        name: 'total_value', label: 'Totale valore IVA', group: 'Contratto', format: 'currency', tableSort: 9, show: false, crud: ["select", "insert", "update"],
        form: { name: 'total_value', type: 'currency', isRequired: false, formSort: 9 }
    },
    {
        name: 'total_value_vat', label: 'Tariffa oraria lordo', group: 'Contratto', format: 'currency', tableSort: 10, show: false, crud: ["select", "insert", "update"],
        form: { name: 'total_value_vat', type: 'currency', isRequired: false, formSort: 10 }
    },
    {
        name: 'total_value_gross', label: 'Totale valore lordo', group: 'Contratto', format: 'currency', tableSort: 11, show: false, crud: ["select", "insert", "update"],
        form: { name: 'total_value_gross', type: 'currency', isRequired: false, formSort: 11 }
    },
    { name: 'creation', label: 'Creata', format: 'datetime', crud: ['select'], tableSort: 12, show: false },
    { name: 'edit', label: 'Modificata', format: 'datetime', crud: ['select'], tableSort: 13, show: false },
    {
        name: 'active', label: 'Attiva', format: 'boolean', crud: ['select', 'update'], tableSort: 14,
        form: { type: 'boolean', name: 'active', isRequired: false, formSort: 14 }
    },
    { name: 'editable', label: 'Modificabile', format: 'hidden', crud: ['select'] }
];
networkList.filters = [
    //{field:'active', value:1, operator:'='} // solo reti attive
];
networkList.options = {
    label: 'Reti',
    title: 'Elenco reti',
    detailTitle: 'Rete',
    // titleClass: '',
    // tableClass: '',
    // responsive: true,
    sorting: true,
    filterAll: true,
    filterHeader: false,
    pagination: true,
    pageSizeOptions: [10, 25, 100],
    pageSize: 25,
    rowClick: 'info',
    rowActionMenu: true,
    rowActionDetail: true,
    rowActionEdit: true,
    exportTypes: ["xlsx"],
    defaultSortActive: 'description',
    defaultSortDirection: 'asc'
};
networkList.linked = [
    {
        title: 'Istituti associati', sourceName: 'schools', idJoin: 'id_network', routerLink: 'istituti', routerId: 'id', fields: [
            { name: 'id', label: 'Id', form: null, crud: ['select'], format: 'hidden', tableSort: 0 },
            { name: 'id_network', label: 'Id Rete', form: null, crud: ['select'], format: 'hidden', tableSort: 0 },
            { name: 'description', label: 'Istituto', form: null, crud: ['select'], format: 'string', tableSort: 1 },
            { name: 'typology', label: 'Tipo', form: null, crud: ['select'], format: 'string', tableSort: 2 },
            { name: 'mechanical_code', label: 'Codice meccanografico', form: null, crud: ['select'], format: 'string', tableSort: 3 },
            { name: 'description_network', label: 'Rete', form: null, crud: ['select'], format: 'string', tableSort: 4 },
            { name: 'active', label: 'Istituto attivo', form: null, crud: ['select'], format: 'boolean', tableSort: 5 }
        ],
        filters: []
    }
];

const schoolList = new EntityModel('');
schoolList.model = typeof SchoolModel;
schoolList.sourceName = 'schools';
schoolList.fields = [
    {
        name: 'id', label: 'ID', format: 'hidden', crud: ['select', 'update'],
        form: { type: 'hidden', name: 'id', pattern: "^[0-9]*$" }
    },
    {
        name: 'description', label: 'Nome istituto', format: 'string', tableSort: 1, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'description', placeholder: 'Inserisci il nome dell\'istituto', isRequired: true, maxLength: 45, formSort: 1 }
    },
    {
        name: 'typology', label: 'Tipo', format: 'string', tableSort: 2, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'typology', placeholder: 'Inserisci il tipo', isRequired: true, maxLength: 20, formSort: 2 }
    },
    {
        name: 'mechanical_code', label: 'Codice meccanografico', format: 'string', tableSort: 3, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'mechanical_code', placeholder: 'Inserisci il codice', isRequired: true, maxLength: 20, formSort: 3 }
    },
    {
        name: 'id_network', label: 'Rete', form: {
            type: 'select',
            name: 'id_network',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'networks',
                placeholder: 'Selezionare una rete',
                id: 'id',
                label: 'description',
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo reti attive
            },
            formSort: 4
        }, format: 'hidden', crud: ['select', 'insert', 'update']
    },
    { name: 'description_network', label: 'Rete', format: 'string', tableSort: 4, crud: ['select'] },
    { name: 'active_network', label: 'Rete attiva', format: 'boolean', tableSort: 5, crud: ['select'], show: false },
    {
        name: 'lead', label: 'Capofila', format: 'boolean', tableSort: 6, crud: ['select', 'insert', 'update'],
        form: { type: 'boolean', name: 'lead', isRequired: true, defaultValue: 0, formSort: 5 }
    },
    {
        name: 'address', label: 'Indirizzo', group: 'Indirizzo', format: 'string', tableSort: 7, crud: ['select', 'insert', 'update'], show: false,
        form: { type: 'text', name: 'address', placeholder: 'Inserisci l\'indirizzo', isRequired: false, maxLength: 50, formSort: 6 }
    },
    {
        name: 'house_number', label: 'N. Civico', group: 'Indirizzo', format: 'string', tableSort: 8, crud: ['select', 'insert', 'update'], show: false,
        form: { type: 'text', name: 'house_number', placeholder: 'Inserisci il numero civico', isRequired: false, maxLength: 5, formSort: 7 }
    },
    {
        name: 'city', label: 'Città', group: 'Indirizzo', format: 'string', tableSort: 9, crud: ['select', 'insert', 'update'], show: false,
        form: { type: 'text', name: 'city', placeholder: 'Inserisci la città', isRequired: false, maxLength: 50, formSort: 9 }
    },
    {
        name: 'province', label: 'Provincia', group: 'Indirizzo', format: 'string', tableSort: 10, crud: ['select', 'insert', 'update'], show: false,
        form: { type: 'text', name: 'province', placeholder: 'Inserisci la provincia', isRequired: false, maxLength: 2, formSort: 8 }
    },
    {
        name: 'email', label: 'Email', group: 'Dettagli', format: 'string', tableSort: 11, crud: ['select', 'insert', 'update'], show: false,
        form: { type: 'text', name: 'email', placeholder: 'Inserisci l\'email', isRequired: false, maxLength: 50, formSort: 10 }
    },
    {
        name: 'pec', label: 'Pec', group: 'Dettagli', format: 'string', tableSort: 12, crud: ['select', 'insert', 'update'], show: false,
        form: { type: 'text', name: 'pec', placeholder: 'Inserisci la pec', isRequired: false, maxLength: 50, formSort: 11 }
    },
    {
        name: 'phone', label: 'Telefono', group: 'Dettagli', format: 'string', tableSort: 13, crud: ['select', 'insert', 'update'], show: false,
        form: { type: 'text', name: 'phone', placeholder: 'Inserisci il telefono', isRequired: false, maxLength: 15, formSort: 12 }
    },
    {
        name: 'fiscal_code', label: 'Codice Fiscale', group: 'Dettagli', format: 'string', tableSort: 14, crud: ['select', 'insert', 'update'], show: false,
        form: { type: 'text', name: 'fiscal_code', placeholder: 'Inserisci il codice fiscale', isRequired: false, maxLength: 20, formSort: 13 }
    },
    {
        name: 'principal_surname', label: 'Cognome Preside', group: 'Preside', format: 'string', tableSort: 15, crud: ['insert', 'update'],
        form: { type: 'text', name: 'principal_surname', placeholder: 'Inserisci il cognome del Preside', isRequired: false, maxLength: 45, formSort: 14 }
    },
    {
        name: 'principal_name', label: 'Nome Preside', group: 'Preside', format: 'string', tableSort: 16, crud: ['insert', 'update'],
        form: { type: 'text', name: 'principal_name', placeholder: 'Inserisci il nome del Preside', isRequired: false, maxLength: 45, formSort: 15 }
    },
    { name: 'principal_fullname', label: 'Preside', group: 'Preside', format: 'string', tableSort: 17, crud: ['select'] },
    {
        name: 'principal_fiscal_code', label: 'Codice fiscale Preside', group: 'Preside', format: 'string', tableSort: 18, crud: ['select', 'insert', 'update'], show: false,
        form: { type: 'text', name: 'principal_fiscal_code', placeholder: 'Inserisci il CF del Preside', isRequired: false, minLength: 16, maxLength: 16, customValidation: 'codiceFiscale', formSort: 16 }
    },
    {
        name: 'branch_office_1', label: 'Sede distaccata 1', group: 'Sedi distaccate', format: 'string', tableSort: 19, crud: ['select', 'insert', 'update'], show: false,
        form: { type: 'text', name: 'branch_office_1', placeholder: 'Inserisci il nome della sede', isRequired: false, maxLength: 100, formSort: 17 }
    },
    {
        name: 'branch_office_2', label: 'Sede distaccata 2', group: 'Sedi distaccate', format: 'string', tableSort: 20, crud: ['select', 'insert', 'update'], show: false,
        form: { type: 'text', name: 'branch_office_2', placeholder: 'Inserisci il nome della sede', isRequired: false, maxLength: 100, formSort: 18 }
    },
    {
        name: 'branch_office_3', label: 'Sede distaccata 3', group: 'Sedi distaccate', format: 'string', tableSort: 21, crud: ['select', 'insert', 'update'], show: false,
        form: { type: 'text', name: 'branch_office_3', placeholder: 'Inserisci il nome della sede', isRequired: false, maxLength: 100, formSort: 19 }
    },
    {
        name: 'branch_office_4', label: 'Sede distaccata 4', group: 'Sedi distaccate', format: 'string', tableSort: 22, crud: ['select', 'insert', 'update'], show: false,
        form: { type: 'text', name: 'branch_office_4', placeholder: 'Inserisci il nome della sede', isRequired: false, maxLength: 100, formSort: 20 }
    },
    {
        name: 'branch_office_5', label: 'Sede distaccata 5', group: 'Sedi distaccate', format: 'string', tableSort: 23, crud: ['select', 'insert', 'update'], show: false,
        form: { type: 'text', name: 'branch_office_5', placeholder: 'Inserisci il nome della sede', isRequired: false, maxLength: 100, formSort: 21 }
    },
    { name: 'creation', label: 'Creata', format: 'datetime', tableSort: 24, crud: ['select'], show: false },
    { name: 'edit', label: 'Modificata', format: 'datetime', tableSort: 25, crud: ['select'], show: false },
    {
        name: 'active', label: 'Istituto attivo', format: 'boolean', tableSort: 26, crud: ['select', 'update'],
        form: { type: 'boolean', name: 'active', isRequired: false, formSort: 22 }
    },
    { name: 'editable', label: 'Modificabile', format: 'hidden', crud: ['select'] }
];
schoolList.filters = [
    // {field:'active', value:1, operator:'='} // solo istituti attivi
];
schoolList.options = {
    label: 'Istituti',
    title: 'Elenco istituti',
    detailTitle: 'Istituto',
    // titleClass: '',
    // tableClass: '',
    // responsive: true,
    sorting: true,
    filterAll: true,
    filterHeader: true,
    pagination: true,
    pageSizeOptions: [10, 25, 100],
    pageSize: 10,
    rowClick: 'info',
    rowActionMenu: true,
    rowActionDetail: true,
    rowActionEdit: true,
    exportTypes: ["xlsx"],
    defaultSortActive: 'description',
    defaultSortDirection: 'asc'
};
schoolList.linked = [
    {
        title: 'Operatori associati', sourceName: 'schools_users', idJoin: 'id_school', routerLink: 'operatori', routerId: 'id_user', fields: [
            { name: 'id', label: 'Id', form: null, crud: ['select'], format: 'hidden', tableSort: 0 },
            { name: 'id_user', label: 'Id Operatore', form: null, crud: ['select'], format: 'hidden', tableSort: 0 },
            { name: 'username_user', label: 'Username', form: null, crud: ['select'], format: 'string', tableSort: 1 },
            { name: 'fullname_user', label: 'Nominativo', form: null, crud: ['select'], format: 'string', tableSort: 3 },
            { name: 'phone_user', label: 'Telefono', form: null, crud: ['select'], format: 'string', tableSort: 4 },
            { name: 'email_user', label: 'Email', form: null, crud: ['select'], format: 'string', tableSort: 5 },
            { name: 'description_contract', label: 'Tipo contratto', form: null, crud: ['select'], format: 'string', tableSort: 6 },
            // { name: 'active', label: 'Legame attivo', form: null, crud: ['select'], format: 'boolean', tableSort: 7 },
            { name: 'active_user', label: 'Attivo', form: null, crud: ['select'], format: 'boolean', tableSort: 8 }
        ],
        filters: [{ field: 'active', value: 1, operator: '=' }], // solo relazioni attive
        select: {
            sourceName: 'users',
            placeholder: 'Selezionare un operatore',
            id: 'id',
            label: 'fullname',
            filters: [{ field: 'active', value: 1, operator: '=' }] // solo operatori attivi
        }
    },
    {
        title: 'Allievi associati', sourceName: 'students', idJoin: 'id_school', routerLink: 'allievi', routerId: 'id', fields: [
            { name: 'id', label: 'ID', form: null, format: 'hidden', crud: ['select', 'update'], tableSort: 0 },
            { name: 'name', label: 'Nome', form: null, format: 'string', crud: ['select', 'insert', 'update'], tableSort: 1 },
            { name: 'surname', label: 'Cognome', form: null, format: 'string', crud: ['select', 'insert', 'update'], tableSort: 2 },
            { name: 'class', label: 'Classe', form: null, format: 'string', crud: ['select', 'insert', 'update'], tableSort: 3 },
            { name: 'creation', label: 'Creato', form: null, format: 'datetime', crud: ['select'], tableSort: 4 },
            { name: 'edit', label: 'Modificato', form: null, format: 'datetime', crud: ['select'], tableSort: 5 },
            { name: 'active', label: 'Allievo attivo', form: null, format: 'boolean', crud: ['select', 'update'], tableSort: 6 }
        ],
        filters: []
    }
];

const activityList = new EntityModel('');
activityList.model = typeof ActivityModel;
activityList.sourceName = 'activities';
activityList.fields = [
    { name: 'id', label: 'ID', form: { type: 'hidden', name: 'id', isRequired: false, pattern: "^[0-9]*$" }, format: 'hidden', crud: ['select', 'update'] },
    { name: 'description', label: 'Descrizione', form: { type: 'text', name: 'description', placeholder: 'Inserisci la descrizione dell\'attività', isRequired: true, maxLength: 45, formSort: 1 }, format: 'string', crud: ['select', 'insert', 'update'], tableSort: 1 },
    { name: 'creation', label: 'Creata', format: 'datetime', crud: ['select'], tableSort: 2 },
    { name: 'active', label: 'Attiva', form: { type: 'boolean', name: 'active', isRequired: false, pattern: "", formSort: 2 }, format: 'boolean', crud: ['select', 'update'], tableSort: 3 },
    { name: 'editable', label: 'Modificabile', format: 'hidden', crud: ['select'] }
];
activityList.filters = [
    // {field:'active', value:1, operator:'='} // solo reti attive
];
activityList.options = {
    label: 'Attività',
    title: 'Elenco attività',
    detailTitle: 'Attività',
    // titleClass: '',
    // tableClass: '',
    // responsive: true,
    sorting: true,
    filterAll: true,
    filterHeader: true,
    pagination: true,
    pageSizeOptions: [10, 25, 100],
    pageSize: 10,
    rowClick: 'edit',
    rowActionMenu: false,
    rowActionDetail: true,
    rowActionEdit: true,
    defaultSortActive: 'description',
    defaultSortDirection: 'asc'
};

const contractList = new EntityModel('');
contractList.model = typeof ContractModel;
contractList.sourceName = 'contracts';
contractList.fields = [
    { name: 'id', label: 'ID', form: { type: 'hidden', name: 'id', isRequired: false, pattern: "^[0-9]*$" }, format: 'hidden', crud: ['select', 'update'] },
    { name: 'description', label: 'Descrizione', form: { type: 'text', name: 'description', placeholder: 'Inserisci la descrizione del contratto', isRequired: true, maxLength: 45, formSort: 1 }, format: 'string', crud: ['select', 'insert', 'update'], tableSort: 1 },
    { name: 'creation', label: 'Creata', format: 'datetime', crud: ['select'], tableSort: 2 },
    { name: 'active', label: 'Attiva', form: { type: 'boolean', name: 'active', isRequired: false, formSort: 2 }, format: 'boolean', crud: ['select', 'update'], tableSort: 3 },
    { name: 'editable', label: 'Modificabile', format: 'hidden', crud: ['select'] }
];
contractList.filters = [
    //{field:'active', value:1, operator:'='} // solo contratti attivi
];
contractList.options = {
    label: 'Contratti',
    title: 'Elenco contratti',
    detailTitle: 'Contratto',
    // titleClass: '',
    // tableClass: '',
    // responsive: true,
    sorting: true,
    filterAll: true,
    filterHeader: true,
    pagination: true,
    pageSizeOptions: [10, 25, 100],
    pageSize: 10,
    rowClick: 'edit',
    rowActionMenu: false,
    rowActionDetail: true,
    rowActionEdit: true
};

const studentList = new EntityModel('');
studentList.model = typeof StudentsModel;
studentList.sourceName = 'students';
studentList.fields = [
    {
        name: 'id', label: 'ID', format: 'hidden', crud: ['select', 'update'],
        form: { type: 'hidden', name: 'id', isRequired: false, pattern: "^[0-9]*$" }
    },
    {
        name: 'name', label: 'Nome', group: 'Generalità', format: 'string', crud: ['insert', 'update'],
        form: { type: 'text', name: 'name', placeholder: 'Inserisci il nome', isRequired: true, maxLength: 45, formSort: 1 }
    },
    {
        name: 'surname', label: 'Cognome', group: 'Generalità', format: 'string', crud: ['insert', 'update'],
        form: { type: 'text', name: 'surname', placeholder: 'Inserisci il cognome', isRequired: true, maxLength: 45, formSort: 2 }
    },
    { name: 'fullname', label: 'Allievo', group: 'Generalità', format: 'string', crud: ['select'], tableSort: 1 },
    {
        name: 'fiscal_code', label: 'Codice fiscale', group: 'Generalità', format: 'string', tableSort: 2, crud: ['select', 'insert', 'update', 'upsert'],
        form: { name: 'fiscal_code', type: 'text', isRequired: true, minLength: 16, maxLength: 16, customValidation: 'codiceFiscale', placeholder: 'Inserisci il codice fiscale', formSort: 3 }
    },
    {
        name: 'class', label: 'Classe', group: 'Riferimenti scolastici', format: 'string', crud: ['select', 'insert', 'update'], tableSort: 3,
        form: { type: 'text', name: 'class', placeholder: 'Inserisci la classe', isRequired: true, maxLength: 10, formSort: 6 }
    },
    {
        name: 'disability', label: 'Tipo disabilità', group: 'Dettagli', format: 'string', tableSort: 4, crud: ['select', 'insert', 'update'], show: false,
        form: { name: 'disability', type: 'textarea', isRequired: false, maxLength: 16, placeholder: 'Inserisci una descrizione', formSort: 4 }
    },
    {
        name: 'multidisability', label: 'Pluridisabilità', group: 'Dettagli', format: 'boolean', crud: ['select', 'insert', 'update'], show: false, tableSort: 5,
        form: { type: 'boolean', name: 'multidisability', isRequired: false, formSort: 7 }
    },
    {
        name: 'bes', label: 'BES', group: 'Dettagli', format: 'boolean', crud: ['select', 'insert', 'update'], show: false, tableSort: 6,
        form: { type: 'boolean', name: 'bes', isRequired: false, formSort: 8 }
    },
    {
        name: 'dsa', label: 'DSA', group: 'Dettagli', format: 'boolean', crud: ['select', 'insert', 'update'], show: false, tableSort: 7,
        form: { type: 'boolean', name: 'dsa', isRequired: false, formSort: 9 }
    },
    {
        name: 'certifications', label: 'Certificazioni', group: 'Dettagli', format: 'boolean', crud: ['select', 'insert', 'update'], show: false, tableSort: 8,
        form: { type: 'boolean', name: 'certifications', isRequired: false, formSort: 10 }
    },
    {
        name: 'notes', label: 'Note', group: 'Note', format: 'string', tableSort: 9, crud: ['select', 'insert', 'update'], show: false,
        form: { name: 'notes', type: 'textarea', isRequired: false, maxLength: 200, placeholder: 'Inserisci delle note', formSort: 10 }
    },
    {
        name: 'id_school', label: 'Istituto', group: 'Riferimenti scolastici', form: {
            type: 'select',
            name: 'id_school',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'schools',
                placeholder: 'Selezionare un\'istituto',
                id: 'id',
                label: 'fulldescription_school',
                filter: true,
                filters: [{ field: 'active', value: 1, operator: '=' }], // solo reti attive,
                upsertId: 'mechanical_code'
            },
            formSort: 5
        }, format: 'hidden', crud: ['select', 'insert', 'update']
    },
    { name: 'description_school', label: 'Istituto', group: 'Riferimenti scolastici', format: 'string', crud: ['select'], tableSort: 10 },
    { name: 'active_school', label: 'Istituto attivo', format: 'boolean', crud: ['select'], show: false, tableSort: 11 },
    { name: 'description_network', label: 'Rete', format: 'string', crud: ['select'], tableSort: 12 },
    { name: 'active_network', label: 'Rete attiva', format: 'boolean', crud: ['select'], show: false, tableSort: 13 },
    { name: 'creation', label: 'Creato', format: 'datetime', crud: ['select'], show: false, tableSort: 14 },
    { name: 'edit', label: 'Modificato', format: 'datetime', crud: ['select'], show: false, tableSort: 15 },
    {
        name: 'active', label: 'Allievo attivo', group: 'Generalità', format: 'boolean', crud: ['select', 'update'], tableSort: 16,
        form: { type: 'boolean', name: 'active', isRequired: false, formSort: 11 }
    },
    { name: 'editable', label: 'Modificabile', format: 'hidden', crud: ['select'] }
];
studentList.filters = [
    //{field:'active', value:1, operator:'='} // solo istituti attivi
];
studentList.options = {
    label: 'Allievi',
    title: 'Elenco allievi',
    detailTitle: 'Allievo',
    // titleClass: '',
    // tableClass: '',
    // responsive: true,
    sorting: true,
    filterAll: true,
    filterHeader: true,
    pagination: true,
    pageSizeOptions: [10, 25, 100, 500],
    pageSize: 10,
    rowClick: 'info',
    rowActionMenu: true,
    rowActionDetail: true,
    rowActionEdit: true,
    exportTypes: ["xlsx"],
    defaultSortActive: 'fullname',
    defaultSortDirection: 'asc'
};

const userList = new EntityModel('');
userList.model = typeof UsersModel;
userList.sourceName = 'users';
userList.fields = [
    {
        name: 'id', label: 'ID', format: 'hidden', crud: ['select', 'update'],
        form: { type: 'hidden', name: 'id', isRequired: false, pattern: "^[0-9]*$" }
    },
    {
        name: 'username', label: 'Username', format: 'string', tableSort: 1, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'username', placeholder: 'Inserisci uno username', isRequired: true, maxLength: 45, formSort: 1 }
    },
    {
        name: 'tmp_password', label: 'Password', format: 'string', crud: ['insert', 'update'], secret: true,
        form: { type: 'text', name: 'tmp_password', placeholder: 'Inserisci la password temporanea', isRequired: false, maxLength: 45, formSort: 2 }
    },
    {
        name: 'name', label: 'Nome', group: 'Generalità', format: 'string', tableSort: 2, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'name', placeholder: 'Inserisci il nome', isRequired: true, maxLength: 45, formSort: 3 }
    },
    {
        name: 'surname', label: 'Cognome', group: 'Generalità', format: 'string', tableSort: 3, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'surname', placeholder: 'Inserisci il cognome', isRequired: true, maxLength: 45, formSort: 4 }
    },
    {
        name: 'id_gender', label: 'Sesso', group: 'Generalità', format: 'hidden', crud: ['select', 'insert', 'update'], form: {
            type: 'select',
            name: 'id_gender',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'genders',
                placeholder: 'Selezionare il sesso',
                id: 'id',
                label: 'description',
                filters: [{ field: 'active', value: 1, operator: '=' }], // solo attivi
                upsertId: 'description'
            },
            formSort: 5
        }
    },
    { name: 'description_gender', label: 'Sesso', group: 'Generalità', format: 'string', tableSort: 4, crud: ['select'] },

    {
        name: 'birth_date', label: 'Data di nascita', group: 'Nascita', format: 'date', tableSort: 5, crud: ['select', 'insert', 'update'],
        form: { type: 'date', name: 'birth_date', placeholder: 'Inserisci il cognome', isRequired: true, formSort: 6 }
    },
    {
        name: 'birth_place', label: 'Luogo di nascita', group: 'Nascita', format: 'string', tableSort: 6, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'birth_place', placeholder: 'Inserisci la città', isRequired: true, maxLength: 50, formSort: 7 }
    },
    {
        name: 'birth_province', label: 'Provincia di nascita', group: 'Nascita', format: 'string', tableSort: 7, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'birth_province', placeholder: 'Inserisci la sigla', isRequired: true, maxLength: 2, formSort: 8 }
    },

    {
        name: 'residence_address', label: 'Indirizzo di residenza', group: 'Residenza', format: 'string', tableSort: 8, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'residence_address', placeholder: 'Inserisci un indirizzo', isRequired: true, maxLength: 50, formSort: 9 }
    },
    {
        name: 'residence_city', label: 'Città di residenza', group: 'Residenza', format: 'string', tableSort: 9, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'residence_city', placeholder: 'Inserisci una città', isRequired: true, maxLength: 50, formSort: 10 }
    },
    {
        name: 'residence_province', label: 'Provincia di residenza', group: 'Residenza', format: 'string', tableSort: 10, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'residence_province', placeholder: 'Inserisci la sigla', isRequired: true, maxLength: 2, formSort: 11 }
    },

    {
        name: 'domicile_address', label: 'Indirizzo del domicilio', group: 'Domicilio', format: 'string', tableSort: 11, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'domicile_address', placeholder: 'Inserisci un indirizzo', isRequired: true, maxLength: 50, formSort: 12 }
    },
    {
        name: 'domicile_city', label: 'Città del domicilio', group: 'Domicilio', format: 'string', tableSort: 12, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'domicile_city', placeholder: 'Inserisci una città', isRequired: true, maxLength: 50, formSort: 13 }
    },
    {
        name: 'domicile_province', label: 'Provincia del domicilio', group: 'Domicilio', format: 'string', tableSort: 13, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'domicile_province', placeholder: 'Inserisci la sigla', isRequired: true, maxLength: 2, formSort: 14 }
    },

    {
        name: 'fiscal_code', label: 'Codice Fiscale', group: 'Generalità', format: 'string', tableSort: 14, crud: ['select', 'insert', 'update', 'upsert'],
        form: { type: 'text', name: 'fiscal_code', placeholder: 'Inserisci il codice fiscale', isRequired: true, minLength: 16, maxLength: 16, customValidation: 'codiceFiscale', formSort: 15 }
    },
    {
        name: 'vat_number', label: 'P_Iva', group: 'Generalità', format: 'string', tableSort: 15, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'vat_number', placeholder: 'Inserisci la partita iva', isRequired: false, maxLength: 11, formSort: 16 }
    },
    {
        name: 'phone', label: 'Telefono', group: 'Recapiti', format: 'string', tableSort: 16, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'phone', placeholder: 'Inserisci il telefono', isRequired: true, maxLength: 15, formSort: 17, pattern: "^(\\+?)[0-9]*$" }
    },
    {
        name: 'email', label: 'Email', group: 'Recapiti', format: 'string', tableSort: 17, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'email', placeholder: 'Inserisci l\'email', isRequired: true, maxLength: 50, formSort: 18 }
    },
    {
        name: 'iban', label: 'IBAN', group: 'Recapiti', format: 'string', tableSort: 18, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'iban', placeholder: 'Inserisci l\'iban', isRequired: true, minLength: 27, maxLength: 27, formSort: 19 }
    },

    {
        name: 'academic_qualification', label: 'Titolo studio', group: 'Formazione', format: 'string', tableSort: 19, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'academic_qualification', placeholder: 'Inserisci il titolo di studio', isRequired: false, maxLength: 50, formSort: 20 }
    },
    {
        name: 'qualification_type', label: 'Tipo titolo di studio', group: 'Formazione', format: 'string', tableSort: 20, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'qualification_type', placeholder: 'Inserisci una tipologia', isRequired: false, maxLength: 50, formSort: 21 }
    },
    {
        name: 'psychologists_register', label: 'Iscrizione Albo Psicologi', group: 'Albo Psicologi', format: 'boolean', tableSort: 21, crud: ['select', 'insert', 'update'],
        form: { type: 'boolean', name: 'psychologists_register', isRequired: false, formSort: 22 }
    },
    {
        name: 'psychologists_register_region', label: 'Regione Albo Psicologi', group: 'Albo Psicologi', format: 'string', tableSort: 22, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'psychologists_register_region', placeholder: 'Inserisci la regione', isRequired: false, conditionalRequired: 'psychologists_register', maxLength: 50, formSort: 23 }
    },
    {
        name: 'psychologists_register_number', label: 'N. Albo Psicologi', group: 'Albo Psicologi', format: 'string', tableSort: 23, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'psychologists_register_number', placeholder: 'Inserisci il numero d\'iscrizione', isRequired: false, conditionalRequired: 'psychologists_register', maxLength: 5, formSort: 24 }
    },

    {
        name: 'notes', label: 'Note/skills', group: 'Note', format: 'string', tableSort: 24, crud: ['select', 'insert', 'update'],
        form: { type: 'textarea', name: 'notes', placeholder: 'Inserisci delle note', isRequired: false, maxLength: 200, formSort: 25 }
    },

    {
        name: 'monthly_hours', label: 'Monte ore settimanali', format: 'string', tableSort: 25, crud: ['select', 'insert', 'update'],
        form: { type: 'number', name: 'monthly_hours', placeholder: 'Inserisci il numero delle ore', isRequired: false, formSort: 26 }
    },
    {
        name: 'admin', label: 'Supervisore', format: 'boolean', tableSort: 26, crud: ['select', 'insert', 'update'],
        form: { type: 'boolean', name: 'admin', isRequired: false, formSort: 31 }
    },
    {
        name: 'principal', label: 'Preside', format: 'boolean', tableSort: 26, crud: ['select', 'insert', 'update'],
        form: { type: 'boolean', name: 'principal', isRequired: false, formSort: 31 }
    },
    {
        name: 'id_contract', label: 'Contratto', form: {
            type: 'select',
            name: 'id_contract',
            isRequired: false,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'contracts',
                placeholder: 'Selezionare un contratto',
                id: 'id',
                label: 'description',
                filters: [{ field: 'active', value: 1, operator: '=' }], // solo contratti attivi
                upsertId: 'description'
            },
            formSort: 29
        }, format: 'hidden', crud: ['select', 'insert', 'update']
    },
    { name: 'description_contract', label: 'Nome contratto', format: 'string', crud: ['select'], tableSort: 30 },
    { name: 'active_contract', label: 'Contratto attivo', format: 'boolean', crud: ['select'], tableSort: 31 },
    {
        name: 'last_contract_date', label: 'Data ultimo contratto', format: 'date', tableSort: 32, crud: ['select', 'insert', 'update'],
        form: { name: 'last_contract_date', type: 'date', isRequired: false, formSort: 30 }
    },
    { name: 'creation', label: 'Creata', format: 'datetime', crud: ['select'], tableSort: 33 },
    { name: 'edit', label: 'Modificata', format: 'datetime', crud: ['select'], tableSort: 34 },
    {
        name: 'active', label: 'Operatore attivo', format: 'boolean', tableSort: 35, crud: ['select', 'update'],
        form: { type: 'boolean', name: 'active', isRequired: false, formSort: 32 }
    },
    { name: 'editable', label: 'Modificabile', format: 'hidden', crud: ['select'] }
];
userList.filters = [
    //{field:'active', value:1, operator:'='} // solo operatori attivi
];
userList.options = {
    label: 'Operatori',
    title: 'Elenco operatori',
    detailTitle: 'Operatore',
    // titleClass: '',
    // tableClass: '',
    // responsive: true,
    sorting: true,
    filterAll: true,
    filterHeader: true,
    pagination: true,
    pageSizeOptions: [10, 25, 100, 500],
    pageSize: 10,
    rowClick: 'detail',
    rowActionMenu: true,
    rowActionDetail: true,
    rowActionEdit: true,
    exportTypes: ['xlsx'],
    defaultSortActive: 'surname',
    defaultSortDirection: 'asc'
};
userList.linked = [
    {
        title: 'Istituti associati', sourceName: 'schools_users', idJoin: 'id_user', routerLink: 'istituti', routerId: 'id_school', fields: [
            { name: 'id', label: 'Id', crud: ['select'], format: 'hidden', tableSort: 0 },
            { name: 'id_school', label: 'Id Istituto', crud: ['select'], format: 'hidden', tableSort: 0 },
            { name: 'description_school', label: 'Istituto', crud: ['select'], format: 'string', tableSort: 1 },
            { name: 'description_network', label: 'Rete', crud: ['select'], format: 'string', tableSort: 2 },
            { name: 'active', label: 'Legame attivo', crud: ['select'], format: 'boolean', tableSort: 3 },
            { name: 'active_school', label: 'Istituto attivo', crud: ['select'], format: 'boolean', tableSort: 4 },
            { name: 'active_network', label: 'Rete attiva', crud: ['select'], format: 'boolean', tableSort: 5 }
        ],
        filters: [{ field: 'active', value: 1, operator: '=' }], // solo relazioni attive
        select: {
            sourceName: 'schools',
            placeholder: 'Selezionare un istituto',
            id: 'id',
            label: 'description',
            filters: [{ field: 'active', value: 1, operator: '=' }] // solo contratti attivi
        }
    }
];

const assistanceList = new EntityModel('');
assistanceList.model = typeof AssistanceModel;
assistanceList.sourceName = 'assistances';
assistanceList.fields = [
    {
        name: 'id', label: 'ID', format: 'hidden', crud: ['select', 'update'],
        form: { type: 'hidden', name: 'id', isRequired: false, pattern: "^[0-9]*$" }
    },
    {
        name: 'code', label: 'Codice', format: 'string', tableSort: 1, crud: ['select', 'insert', 'update'],
        form: { type: 'readonly', name: 'code', defaultValue: 'Autogenerato', isRequired: false, formSort: 1 }
    },
    { name: 'description_year', label: 'Annualità', format: 'string', tableSort: 2, group: 'Quando', crud: ['select'], show: false },
    {
        name: 'execution', label: 'Data', format: 'date', tableSort: 2, group: 'Quando', crud: ['select', 'insert', 'update'],
        form: { type: 'date', name: 'execution', isRequired: true, formSort: 2 }
    },
    {
        name: 'start_time', label: 'Inizio', format: 'time', tableSort: 3, group: 'Quando', crud: ['select', 'insert', 'update'],
        form: { type: 'time', name: 'start_time', isRequired: true, formSort: 3 }
    },
    {
        name: 'end_time', label: 'Fine', format: 'time', tableSort: 4, group: 'Quando', crud: ['select', 'insert', 'update'],
        form: { type: 'time', name: 'end_time', isRequired: true, formSort: 4 }
    },
    { name: 'duration', label: 'Durata', format: 'time', tableSort: 4, group: 'Quando', crud: ['select'], show: false },
    {
        name: 'id_user', label: 'Operatore', format: 'hidden', group: 'Presenza', crud: ['select', 'insert', 'update'],
        form: {
            type: 'select', name: 'id_user', isRequired: true, formSort: 6,
            select: {
                sourceName: 'users',
                placeholder: 'Selezionare un operatore',
                id: 'id',
                label: 'fullname',
                filter: true,
                filters: [
                    { field: 'active', value: 1, operator: '=' }, // solo operatori attivi
                    { field: 'admin', value: 0, operator: '=' } // escludo admin
                ]
            }
        }
    },
    { name: 'fullname_user', label: 'Operatore', format: 'string', tableSort: 15, group: 'Presenza', crud: ['select'] },
    {
        name: 'id_student', label: 'Allievo', format: 'hidden', group: 'Presenza', crud: ['select', 'insert', 'update'],
        form: {
            type: 'select', name: 'id_student', isRequired: true, formSort: 8,
            select: {
                sourceName: 'students',
                placeholder: 'Selezionare un allievo',
                id: 'id',
                label: 'fullname',
                filter: true,
                filters: [
                    { field: 'active', value: 1, operator: '=' }, // solo allievi attivi
                ],
                upSelectFilter: {
                    upField: 'id_school',
                    filterKey: 'id_school'
                }
            }
        }
    },
    { name: 'fullname_student', label: 'Allievo', format: 'string', tableSort: 9, group: 'Presenza', crud: ['select'] },
    {
        name: 'id_activity', label: 'Attività', format: 'hidden', group: 'Presenza', crud: ['select', 'insert', 'update'],
        form: {
            type: 'select', name: 'id_activity', isRequired: true, formSort: 9,
            select: {
                sourceName: 'activities',
                placeholder: "Selezionare un'attività",
                id: 'id',
                label: 'description',
                filters: [
                    { field: 'active', value: 1, operator: '=' }, // solo attività attivi
                ]
            }
        }
    },
    { name: 'description_activity', label: 'Attività', format: 'string', tableSort: 12, group: 'Presenza', crud: ['select'] },
    {
        name: 'id_school', label: 'Istituto', format: 'hidden', group: 'Presenza', crud: ['select', 'insert', 'update'],
        form: {
            type: 'select', name: 'id_school', isRequired: true, formSort: 7,
            select: {
                sourceName: 'schools_users',
                placeholder: "Selezionare un\'istituto",
                id: 'id_school',
                label: 'fulldescription_school',
                filter: true,
                filters: [
                    { field: 'active', value: 1, operator: '=' },
                    { field: 'active_school', value: 1, operator: '=' },
                    { field: 'active_network', value: 1, operator: '=' },
                    { field: 'active_user', value: 1, operator: '=' },
                ],
                distinct: true,
                upSelectFilter: {
                    upField: 'id_user',
                    filterKey: 'id_user'
                }
            }
        }
    },
    { name: 'description_school', label: 'Istituto', format: 'string', tableSort: 7, group: 'Presenza', crud: ['select'] },
    { name: 'class', label: 'Classe', format: 'string', tableSort: 8, group: 'Presenza', crud: ['select'], show: false },
    { name: 'id_network', label: 'ID Rete', format: 'hidden', crud: ['select'] },
    { name: 'description_network', label: 'Rete', format: 'string', tableSort: 6, group: 'Presenza', crud: ['select'], show: false },
    { name: 'creation', label: 'Creata', format: 'datetime', tableSort: 19, crud: ['select'], show: false },
    { name: 'edit', label: 'Modificata', format: 'datetime', tableSort: 20, crud: ['select'], show: false },
    {
        name: 'substitution', label: 'Sostituzione', format: 'boolean', tableSort: 5, group: 'Presenza', crud: ['select', 'insert', 'update'], show: false,
        form: { type: 'boolean', name: 'substitution', isRequired: false, defaultValue: 0, formSort: 10 }
    },
    {
        name: 'active', label: 'Presenza attiva', format: 'boolean', tableSort: 21, crud: ['select', 'update'],
        form: { type: 'boolean', name: 'active', isRequired: false, formSort: 11 }, show: false
    },
    { name: 'editable', label: 'Modificabile', format: 'hidden', crud: ['select'] }
];
assistanceList.filters = [
    // { field: 'active', value: 1, operator: '=' }, // solo operatori attivi
];
assistanceList.options = {
    label: 'Presenze',
    title: 'Elenco presenze',
    detailTitle: 'Presenza',
    // titleClass: '',
    // tableClass: '',
    // responsive: true,
    sorting: true,
    filterAll: true,
    filterHeader: true,
    pagination: true,
    pageSizeOptions: [10, 25, 100, 1000],
    pageSize: 10,
    rowClick: 'info',
    rowActionMenu: true,
    rowActionDetail: true,
    rowActionEdit: true,
    exportTypes: ["xlsx"],
    defaultSortActive: 'execution',
    defaultSortDirection: 'desc',
    defaultClientFilter: [
        { field: 'active', operator: '=', value: 1 },
        //{ field: 'execution', operator: '=', value: 'LAST_MONTH' }
    ]
};
assistanceList.linked = [
    {
        title: 'Segnalazioni associate', sourceName: 'requests', idJoin: 'id_assistance', routerLink: 'segnalazioni', routerId: 'id', fields: [
            { name: 'id', label: 'Id', crud: ['select'], format: 'hidden' },
            { name: 'description_short', label: 'Descrizione', crud: ['select'], format: 'string', tableSort: 1 },
            { name: 'open', label: 'Stato', crud: ['select'], format: 'boolean', tableSort: 2 },
            { name: 'fullname_user_open', label: 'Creata da', crud: ['select'], format: 'string', tableSort: 3 },
            { name: 'creation', label: 'Creata il', crud: ['select'], format: 'boolean', tableSort: 4 },
            { name: 'fullname_user_close', label: 'Chiusa da', crud: ['select'], format: 'string', tableSort: 5 }
        ],
        filters: [],
    }
];

const assistanceFullList = new EntityModel('');
assistanceFullList.model = typeof AssistanceFullModel;
assistanceFullList.sourceName = 'assistances_full';
assistanceFullList.filters = [];
assistanceFullList.fields = [
    // year
    { name: 'id_year', label: 'ID Anno', format: 'hidden', group: 'Annualità', crud: ['select'] },
    { name: 'description_year', label: 'Anno', format: 'string', group: 'Annualità', tableSort: 1, crud: ['select'] },
    { name: 'day_start_year', label: 'Inizio Anno', format: 'date', group: 'Annualità', tableSort: 2, crud: ['select'], show: false },
    { name: 'day_end_year', label: 'Fine Anno', format: 'date', group: 'Annualità', tableSort: 3, crud: ['select'], show: false },
    // assistance
    { name: 'id_assistance', label: 'ID Presenza', format: 'hidden', group: 'Presenza', crud: ['select'] },
    { name: 'code_assistance', label: 'Codice Presenza', format: 'string', group: 'Presenza', tableSort: 4, crud: ['select'] },
    { name: 'execution_assistance', label: 'Esecuzione Presenza', format: 'date', group: 'Presenza', tableSort: 5, crud: ['select'] },
    { name: 'start_time_assistance', label: 'Inizio Presenza', format: 'time', group: 'Presenza', tableSort: 6, crud: ['select'], show: false },
    { name: 'end_time_assistance', label: 'Fine Presenza', format: 'time', group: 'Presenza', tableSort: 7, crud: ['select'], show: false },
    { name: 'duration_assistance', label: 'Durata Presenza', format: 'time', group: 'Presenza', tableSort: 8, crud: ['select'] },
    { name: 'substitution_assistance', label: 'Sostituzione Presenza', format: 'boolean', group: 'Presenza', tableSort: 9, crud: ['select'], show: false },
    { name: 'creation_assistance', label: 'Creazione Presenza', format: 'datetime', group: 'Presenza', tableSort: 10, crud: ['select'], show: false },
    { name: 'edit_assistance', label: 'Modifica Presenza', format: 'datetime', group: 'Presenza', tableSort: 11, crud: ['select'], show: false },
    // user
    { name: 'id_user', label: 'ID Operatore', format: 'hidden', group: 'Operatore', crud: ['select'] },
    { name: 'username_user', label: 'Username', format: 'string', group: 'Operatore', tableSort: 12, crud: ['select'], show: false },
    { name: 'name_user', label: 'Nome operatore', format: 'string', group: 'Operatore', tableSort: 13, crud: ['select'], show: false },
    { name: 'surname_user', label: 'Cognome operatore', format: 'string', group: 'Operatore', tableSort: 14, crud: ['select'], show: false},
    { name: 'fullname_user', label: 'Operatore', format: 'string', group: 'Operatore', tableSort: 15, crud: ['select'] },
    { name: 'birth_date_user', label: 'Data di nascita', format: 'date', group: 'Operatore', tableSort: 16, crud: ['select'], show: false },
    { name: 'birth_place_user', label: 'Luogo di nascita', format: 'string', group: 'Operatore', tableSort: 17, crud: ['select'], show: false },
    { name: 'birth_province_user', label: 'Provincia di nascita', format: 'string', group: 'Operatore', tableSort: 18, crud: ['select'], show: false },
    { name: 'residence_address_user', label: 'Indirizzo residenza', format: 'string', group: 'Operatore', tableSort: 19, crud: ['select'], show: false },
    { name: 'residence_city_user', label: 'Città residenza', format: 'string', group: 'Operatore', tableSort: 20, crud: ['select'], show: false },
    { name: 'residence_province_user', label: 'Provincia residenza', format: 'string', group: 'Operatore', tableSort: 21, crud: ['select'], show: false },
    { name: 'domicile_address_user', label: 'Indirizzo domicilio', format: 'string', group: 'Operatore', tableSort: 22, crud: ['select'], show: false },
    { name: 'domicile_city_user', label: 'Città domicilio', format: 'string', group: 'Operatore', tableSort: 23, crud: ['select'], show: false },
    { name: 'domicile_province_user', label: 'Provincia domicilio', format: 'string', group: 'Operatore', tableSort: 24, crud: ['select'], show: false },
    { name: 'fiscal_code_user', label: 'Codice fiscale', format: 'string', group: 'Operatore', tableSort: 25, crud: ['select'], show: false },
    { name: 'vat_number_user', label: 'Partita IVA', format: 'string', group: 'Operatore', tableSort: 26, crud: ['select'], show: false },
    { name: 'phone_user', label: 'Telefono', format: 'string', group: 'Operatore', tableSort: 27, crud: ['select'], show: false },
    { name: 'email_user', label: 'Email', format: 'string', group: 'Operatore', tableSort: 28, crud: ['select'], show: false },
    { name: 'iban_user', label: 'IBAN', format: 'string', group: 'Operatore', tableSort: 29, crud: ['select'], show: false },
    { name: 'academic_qualification_user', label: 'Titolo di studio', format: 'string', group: 'Operatore', tableSort: 30, crud: ['select'], show: false },
    { name: 'qualification_type_user', label: 'Tipo titolo di studio', format: 'string', group: 'Operatore', tableSort: 31, crud: ['select'], show: false },
    { name: 'psychologists_register_user', label: 'Iscrizione Albo Psicologi', format: 'boolean', group: 'Operatore', tableSort: 32, crud: ['select'], show: false },
    { name: 'psychologists_register_region_user', label: 'Regione Albo Psicologi', format: 'string', group: 'Operatore', tableSort: 33, crud: ['select'], show: false },
    { name: 'psychologists_register_number_user', label: 'N. Albo Psicologi', format: 'string', group: 'Operatore', tableSort: 34, crud: ['select'], show: false },
    { name: 'notes_user', label: 'Note', format: 'string', group: 'Operatore', tableSort: 35, crud: ['select'], show: false },
    { name: 'monthly_hours_user', label: 'Ore mensili', format: 'number', group: 'Operatore', tableSort: 36, crud: ['select'], show: false },
    { name: 'last_contract_date_user', label: 'Data ultimo contratto', format: 'date', group: 'Operatore', tableSort: 37, crud: ['select'], show: false },
    { name: 'admin_user', label: 'Amministratore', format: 'boolean', group: 'Operatore', tableSort: 38, crud: ['select'], show: false },
    // contract
    { name: 'id_contract', label: 'ID Contratto', format: 'hidden', group: 'Contratto', crud: ['select'] },
    { name: 'description_contract', label: 'Descrizione contratto', format: 'string', group: 'Contratto', tableSort: 39, crud: ['select'], show: false },
    // student
    { name: 'id_student', label: 'ID Studente', format: 'hidden', group: 'Studente', crud: ['select'] },
    { name: 'name_student', label: 'Nome studente', format: 'string', group: 'Studente', tableSort: 40, crud: ['select'], show: false },
    { name: 'surname_student', label: 'Cognome studente', format: 'string', group: 'Studente', tableSort: 41, crud: ['select'], show: false },
    { name: 'fullname_student', label: 'Studente', format: 'string', group: 'Studente', tableSort: 42, crud: ['select'] },
    { name: 'fiscal_code_student', label: 'Codice fiscale studente', format: 'string', group: 'Studente', tableSort: 43, crud: ['select'], show: false },
    { name: 'disability_student', label: 'Disabilità', format: 'boolean', group: 'Studente', tableSort: 44, crud: ['select'], show: false },
    { name: 'multidisability_student', label: 'Multidisabilità', format: 'boolean', group: 'Studente', tableSort: 45, crud: ['select'], show: false },
    { name: 'bes_student', label: 'BES', format: 'boolean', group: 'Studente', tableSort: 46, crud: ['select'], show: false },
    { name: 'dsa_student', label: 'DSA', format: 'boolean', group: 'Studente', tableSort: 47, crud: ['select'], show: false },
    { name: 'certifications_student', label: 'Certificazioni', format: 'string', group: 'Studente', tableSort: 48, crud: ['select'], show: false },
    { name: 'notes_student', label: 'Note', format: 'string', group: 'Studente', tableSort: 49, crud: ['select'], show: false },
    { name: 'class_student', label: 'Classe', format: 'string', group: 'Studente', tableSort: 50, crud: ['select'] },
    // gender
    { name: 'id_gender', label: 'ID Genere', format: 'hidden', group: 'Genere', crud: ['select'], show: false },
    { name: 'description_gender', label: 'Descrizione genere', format: 'string', group: 'Genere', tableSort: 51, crud: ['select'], show: false },
    // activity
    { name: 'id_activity', label: 'ID Attività', format: 'hidden', group: 'Attività', crud: ['select'] },
    { name: 'description_activity', label: 'Descrizione attività', format: 'string', group: 'Attività', tableSort: 52, crud: ['select'] },
    // school
    { name: 'id_school', label: 'ID Scuola', format: 'hidden', group: 'Scuola', crud: ['select'] },
    { name: 'description_school', label: 'Descrizione scuola', format: 'string', group: 'Scuola', tableSort: 53, crud: ['select'] },
    { name: 'typology_school', label: 'Tipologia scuola', format: 'string', group: 'Scuola', tableSort: 54, crud: ['select'], show: false },
    { name: 'mechanical_code_school', label: 'Codice meccanografico', format: 'string', group: 'Scuola', tableSort: 55, crud: ['select'] },
    { name: 'lead_school', label: 'Lead scuola', format: 'string', group: 'Scuola', tableSort: 56, crud: ['select'], show: false },
    { name: 'address_school', label: 'Indirizzo scuola', format: 'string', group: 'Scuola', tableSort: 57, crud: ['select'], show: false },
    { name: 'house_number_school', label: 'Numero civico scuola', format: 'string', group: 'Scuola', tableSort: 58, crud: ['select'], show: false },
    { name: 'city_school', label: 'Città scuola', format: 'string', group: 'Scuola', tableSort: 59, crud: ['select'], show: false },
    { name: 'province_school', label: 'Provincia scuola', format: 'string', group: 'Scuola', tableSort: 60, crud: ['select'], show: false },
    { name: 'email_school', label: 'Email scuola', format: 'string', group: 'Scuola', tableSort: 61, crud: ['select'], show: false },
    { name: 'pec_school', label: 'PEC scuola', format: 'string', group: 'Scuola', tableSort: 62, crud: ['select'], show: false },
    { name: 'phone_school', label: 'Telefono scuola', format: 'string', group: 'Scuola', tableSort: 63, crud: ['select'], show: false },
    { name: 'fiscal_code_school', label: 'Codice fiscale scuola', format: 'string', group: 'Scuola', tableSort: 64, crud: ['select'], show: false },
    { name: 'principal_surname_school', label: 'Cognome preside', format: 'string', group: 'Scuola', tableSort: 65, crud: ['select'], show: false },
    { name: 'principal_name_school', label: 'Nome preside', format: 'string', group: 'Scuola', tableSort: 66, crud: ['select'], show: false },
    { name: 'principal_fullname_school', label: 'Preside', format: 'string', group: 'Scuola', tableSort: 66, crud: ['select'], show: false },
    { name: 'principal_fiscal_code_school', label: 'Codice fiscale preside', format: 'string', group: 'Scuola', tableSort: 67, crud: ['select'], show: false },
    { name: 'branch_office_1_school', label: 'Sede 1 scuola', format: 'string', group: 'Scuola', tableSort: 68, crud: ['select'], show: false },
    { name: 'branch_office_2_school', label: 'Sede 2 scuola', format: 'string', group: 'Scuola', tableSort: 69, crud: ['select'], show: false },
    { name: 'branch_office_3_school', label: 'Sede 3 scuola', format: 'string', group: 'Scuola', tableSort: 70, crud: ['select'], show: false },
    { name: 'branch_office_4_school', label: 'Sede 4 scuola', format: 'string', group: 'Scuola', tableSort: 71, crud: ['select'], show: false },
    { name: 'branch_office_5_school', label: 'Sede 5 scuola', format: 'string', group: 'Scuola', tableSort: 72, crud: ['select'], show: false },
    // network
    { name: 'id_network', label: 'ID Rete', format: 'hidden', group: 'Rete', crud: ['select'] },
    { name: 'description_network', label: 'Descrizione rete', format: 'string', group: 'Rete', tableSort: 73, crud: ['select'] },
    { name: 'contract_start_network', label: 'Inizio contratto', format: 'string', group: 'Rete', tableSort: 74, crud: ['select'], show: false },
    { name: 'contract_end_network', label: 'Fine contratto', format: 'string', group: 'Rete', tableSort: 75, crud: ['select'], show: false },
    { name: 'mono_network', label: 'Mono rete', format: 'boolean', group: 'Rete', tableSort: 76, crud: ['select'], show: false },
    { name: 'hours_network', label: 'Ore rete', format: 'number', group: 'Rete', tableSort: 77, crud: ['select'], show: false },
    { name: 'hourly_rate_network', label: 'Tariffa oraria', format: 'number', group: 'Rete', tableSort: 78, crud: ['select'], show: false },
    { name: 'hourly_rate_vat_network', label: 'Tariffa oraria IVA', format: 'number', group: 'Rete', tableSort: 79, crud: ['select'], show: false },
    { name: 'hourly_rate_gross_network', label: 'Tariffa oraria lorda', format: 'number', group: 'Rete', tableSort: 80, crud: ['select'], show: false },
    { name: 'total_value_network', label: 'Valore totale', format: 'number', group: 'Rete', tableSort: 81, crud: ['select'], show: false },
    { name: 'total_value_vat_network', label: 'Valore totale IVA', format: 'number', group: 'Rete', tableSort: 82, crud: ['select'], show: false },
    { name: 'total_value_gross_network', label: 'Valore totale lordo', format: 'number', group: 'Rete', tableSort: 83, crud: ['select'], show: false },
    { name: 'editable', label: 'Modificabile', format: 'hidden', crud: ['select'] },
];
assistanceFullList.options = {
    label: 'Presenze',
    title: 'Archivio presenze',
    detailTitle: 'Presenza',
    // titleClass: '',
    // tableClass: '',
    // responsive: true,
    sorting: true,
    filterAll: true,
    filterHeader: true,
    pagination: true,
    pageSizeOptions: [10, 25, 100, 1000],
    pageSize: 10,
    rowClick: 'info',
    rowActionMenu: false,
    rowActionDetail: false,
    rowActionEdit: false,
    exportTypes: ["xlsx", "xlsx_full"],
    defaultSortActive: 'execution_assistance',
    defaultSortDirection: 'desc',
    defaultClientFilter: [
        // { field: 'active', operator: '=', value: 1 },
    ]
};
assistanceFullList.linked = [];

const absencesFullList = new EntityModel('');
absencesFullList.model = typeof AbsenceFullModel;
absencesFullList.sourceName = 'absences_full';
absencesFullList.filters = [];
absencesFullList.fields = [
    // year
    { name: 'id_year', label: 'ID Anno', format: 'hidden', group: 'Annualità', crud: ['select'] },
    { name: 'description_year', label: 'Anno', format: 'string', group: 'Annualità', tableSort: 1, crud: ['select'] },
    { name: 'day_start_year', label: 'Inizio Anno', format: 'date', group: 'Annualità', tableSort: 2, crud: ['select'], show: false },
    { name: 'day_end_year', label: 'Fine Anno', format: 'date', group: 'Annualità', tableSort: 3, crud: ['select'], show: false },
    // assistance
    { name: 'id_absence', label: 'ID Assenza', format: 'hidden', group: 'Assenza', crud: ['select'] },
    { name: 'execution_absence', label: 'Esecuzione Assenza', format: 'date', group: 'Assenza', tableSort: 4, crud: ['select'] },
    { name: 'start_time_absence', label: 'Inizio Assenza', format: 'time', group: 'Assenza', tableSort: 5, crud: ['select'] },
    { name: 'end_time_absence', label: 'Fine Assenza', format: 'time', group: 'Assenza', tableSort: 6, crud: ['select'] },
    { name: 'justified_absence', label: 'Sostituzione Assenza', format: 'boolean', group: 'Assenza', tableSort: 7, crud: ['select'], show: false },
    { name: 'creation_absence', label: 'Creazione Assenza', format: 'datetime', group: 'Assenza', tableSort: 8, crud: ['select'], show: false },
    { name: 'edit_absence', label: 'Modifica Assenza', format: 'datetime', group: 'Assenza', tableSort: 9, crud: ['select'], show: false },
    // absence_type
    { name: 'id_absence_type', label: 'ID Tipo Assenza', format: 'hidden', group: 'Tipo Assenza', crud: ['select'] },
    { name: 'description_absence_type', label: 'Tipo Assenza', format: 'string', group: 'Tipo Assenza', tableSort: 10, crud: ['select'] },
    // user
    { name: 'id_user', label: 'ID Operatore', format: 'hidden', group: 'Operatore', crud: ['select'] },
    { name: 'username_user', label: 'Username', format: 'string', group: 'Operatore', tableSort: 12, crud: ['select'], show: false },
    { name: 'name_user', label: 'Nome operatore', format: 'string', group: 'Operatore', tableSort: 13, crud: ['select'], show: false },
    { name: 'surname_user', label: 'Cognome operatore', format: 'string', group: 'Operatore', tableSort: 14, crud: ['select'], show: false},
    { name: 'fullname_user', label: 'Operatore', format: 'string', group: 'Operatore', tableSort: 15, crud: ['select'] },
    { name: 'birth_date_user', label: 'Data di nascita', format: 'date', group: 'Operatore', tableSort: 16, crud: ['select'], show: false },
    { name: 'birth_place_user', label: 'Luogo di nascita', format: 'string', group: 'Operatore', tableSort: 17, crud: ['select'], show: false },
    { name: 'birth_province_user', label: 'Provincia di nascita', format: 'string', group: 'Operatore', tableSort: 18, crud: ['select'], show: false },
    { name: 'residence_address_user', label: 'Indirizzo residenza', format: 'string', group: 'Operatore', tableSort: 19, crud: ['select'], show: false },
    { name: 'residence_city_user', label: 'Città residenza', format: 'string', group: 'Operatore', tableSort: 20, crud: ['select'], show: false },
    { name: 'residence_province_user', label: 'Provincia residenza', format: 'string', group: 'Operatore', tableSort: 21, crud: ['select'], show: false },
    { name: 'domicile_address_user', label: 'Indirizzo domicilio', format: 'string', group: 'Operatore', tableSort: 22, crud: ['select'], show: false },
    { name: 'domicile_city_user', label: 'Città domicilio', format: 'string', group: 'Operatore', tableSort: 23, crud: ['select'], show: false },
    { name: 'domicile_province_user', label: 'Provincia domicilio', format: 'string', group: 'Operatore', tableSort: 24, crud: ['select'], show: false },
    { name: 'fiscal_code_user', label: 'Codice fiscale', format: 'string', group: 'Operatore', tableSort: 25, crud: ['select'], show: false },
    { name: 'vat_number_user', label: 'Partita IVA', format: 'string', group: 'Operatore', tableSort: 26, crud: ['select'], show: false },
    { name: 'phone_user', label: 'Telefono', format: 'string', group: 'Operatore', tableSort: 27, crud: ['select'], show: false },
    { name: 'email_user', label: 'Email', format: 'string', group: 'Operatore', tableSort: 28, crud: ['select'], show: false },
    { name: 'iban_user', label: 'IBAN', format: 'string', group: 'Operatore', tableSort: 29, crud: ['select'], show: false },
    { name: 'academic_qualification_user', label: 'Titolo di studio', format: 'string', group: 'Operatore', tableSort: 30, crud: ['select'], show: false },
    { name: 'qualification_type_user', label: 'Tipo titolo di studio', format: 'string', group: 'Operatore', tableSort: 31, crud: ['select'], show: false },
    { name: 'psychologists_register_user', label: 'Iscrizione Albo Psicologi', format: 'boolean', group: 'Operatore', tableSort: 32, crud: ['select'], show: false },
    { name: 'psychologists_register_region_user', label: 'Regione Albo Psicologi', format: 'string', group: 'Operatore', tableSort: 33, crud: ['select'], show: false },
    { name: 'psychologists_register_number_user', label: 'N. Albo Psicologi', format: 'string', group: 'Operatore', tableSort: 34, crud: ['select'], show: false },
    { name: 'notes_user', label: 'Note', format: 'string', group: 'Operatore', tableSort: 35, crud: ['select'], show: false },
    { name: 'monthly_hours_user', label: 'Ore mensili', format: 'number', group: 'Operatore', tableSort: 36, crud: ['select'], show: false },
    { name: 'last_contract_date_user', label: 'Data ultimo contratto', format: 'date', group: 'Operatore', tableSort: 37, crud: ['select'], show: false },
    { name: 'admin_user', label: 'Amministratore', format: 'boolean', group: 'Operatore', tableSort: 38, crud: ['select'], show: false },
    // contract
    { name: 'id_contract', label: 'ID Contratto', format: 'hidden', group: 'Contratto', crud: ['select'] },
    { name: 'description_contract', label: 'Descrizione contratto', format: 'string', group: 'Contratto', tableSort: 39, crud: ['select'] },
    // gender
    { name: 'id_gender', label: 'ID Genere', format: 'hidden', group: 'Genere', crud: ['select'], show: false },
    { name: 'description_gender', label: 'Descrizione genere', format: 'string', group: 'Genere', tableSort: 51, crud: ['select'], show: false },
    { name: 'editable', label: 'Modificabile', format: 'hidden', crud: ['select'] },
];
absencesFullList.options = {
    label: 'Assenze',
    title: 'Archivio assenze',
    detailTitle: 'Assenza',
    // titleClass: '',
    // tableClass: '',
    // responsive: true,
    sorting: true,
    filterAll: true,
    filterHeader: true,
    pagination: true,
    pageSizeOptions: [10, 25, 100, 1000],
    pageSize: 10,
    rowClick: 'info',
    rowActionMenu: false,
    rowActionDetail: false,
    rowActionEdit: false,
    exportTypes: ["xlsx", "xlsx_full"],
    defaultSortActive: 'execution_absence',
    defaultSortDirection: 'desc',
    defaultClientFilter: [
        // { field: 'active', operator: '=', value: 1 },
    ]
};
absencesFullList.linked = [];

const requestList = new EntityModel('');
requestList.model = typeof RequestModel;
requestList.sourceName = 'requests';
requestList.fields = [
    {
        name: 'id', label: 'ID', format: 'hidden', crud: ['select', 'update'],
        form: { type: 'hidden', name: 'id', isRequired: false, pattern: "^[0-9]*$" }
    },
    {
        name: 'description', label: 'Descrizione', format: 'string', tableSort: 1, crud: ['select', 'insert'],
        form: { type: 'textarea', name: 'description', placeholder: 'Inserisci la descrizione', isRequired: true, maxLength: 1000, formSort: 2 }
    },
    {
        name: 'code', label: 'Codice richiesta', format: 'string', tableSort: 2, group: 'Dettagli', crud: ['select', 'insert', 'update'],
        form: { type: 'readonly', name: 'code', defaultValue: 'Autogenerato', isRequired: true, formSort: 1 }
    },
    { name: 'code_assistance', label: 'Presenza collegata', linkText: '/presenze/', linkItemField: 'id_assistance', format: 'string', tableSort: 3, group: 'Dettagli', crud: ['select'] },
    {
        name: 'id_assistance', label: 'Presenza collegata', format: 'hidden', group: 'Dettagli', crud: ['select', 'insert', 'update'],
        form: {
            type: 'select', name: 'id_assistance', isRequired: false, formSort: 4,
            select: {
                sourceName: 'assistances',
                placeholder: "Selezionare una presenza",
                id: 'id',
                label: 'code',
                filter: true,
                filters: [
                    { field: 'active', value: 1, operator: '=' }, // solo presenze attive
                ]
            }
        }
    },
    { name: 'fullname_user_open', label: 'Creata da', format: 'string', tableSort: 4, group: 'Dettagli', crud: ['select'] },
    {
        name: 'id_user_open', label: 'Creata da', format: 'hidden', group: 'Dettagli', crud: ['select', 'insert', 'update'],
        form: {
            type: 'select', name: 'id_user_open', isRequired: true, formSort: 1,
            select: {
                sourceName: 'users',
                placeholder: 'Selezionare un operatore',
                id: 'id',
                label: 'fullname',
                filter: true,
                filters: [
                    { field: 'active', value: 1, operator: '=' }, // solo operatori attivi
                ]
            }
        }
    },
    { name: 'creation', label: 'Creata il', format: 'datetime', tableSort: 5, group: 'Dettagli', crud: ['select'] },
    {
        name: 'open', label: 'In corso', format: 'boolean', tableSort: 6, group: 'Stato', crud: ['select'],
        form: { type: 'boolean', name: 'open', isRequired: true, formSort: 3 }
    },
    { name: 'edit', label: 'Ultima modifica', format: 'datetime', tableSort: 7, group: 'Stato', crud: ['select'] },
    { name: 'fullname_user_close', label: 'Chiusa da', format: 'string', tableSort: 8, group: 'Stato', crud: ['select'] },
    {
        name: 'id_user_close', label: 'Chiusa da', format: 'hidden', group: 'Stato', crud: ['select', 'update'],
        form: {
            type: 'select', name: 'id_user_close', isRequired: false, formSort: 5,
            select: {
                sourceName: 'users',
                placeholder: 'Selezionare un operatore',
                id: 'id',
                label: 'fullname',
                filter: true,
                filters: [
                    { field: 'active', value: 1, operator: '=' }, // solo operatori attivi
                ],
                sort: [{ field: 'admin', direction: 'desc' }, { field: 'fullname', direction: 'asc' }]
            },
            conditionalRequired: "close_date"
        }
    },
    {
        name: 'close_date', label: 'Chiusa il', format: 'date', tableSort: 9, group: 'Stato', crud: ['select', 'update'],
        form: { type: 'date', name: 'close_date', isRequired: false, formSort: 6 }
    },
    {
        name: 'active', label: 'Segnalazione attiva', format: 'boolean', tableSort: 10, group: 'Stato', crud: ['select', 'update'],
        form: { type: 'boolean', name: 'active', isRequired: false, formSort: 7 }
    },
    { name: 'editable', label: 'Modificabile', format: 'hidden', crud: ['select'] }
];
requestList.filters = [
    { field: 'active', value: 1, operator: '=' }, // solo segnalazioni attive
];
requestList.options = {
    label: 'Segnalazioni',
    title: 'Elenco segnalazioni',
    detailTitle: 'Segnalazione',
    // titleClass: '',
    // tableClass: '',
    // responsive: true,
    sorting: true,
    filterAll: true,
    filterHeader: true,
    pagination: true,
    pageSizeOptions: [10, 25, 100],
    pageSize: 10,
    rowClick: 'detail',
    rowActionMenu: true,
    rowActionDetail: true,
    rowActionEdit: true,
    exportTypes: ["xlsx"],
    defaultSortActive: 'creation',
    defaultSortDirection: 'desc'
};
requestList.linked = [
    {
        title: 'Commenti',
        sourceName: 'requests_replies',
        idJoin: 'id_request',
        routerLink: null,
        routerId: null,
        fields: [
            { name: 'id', label: 'Id', crud: ['select'], format: 'hidden' },
            { name: 'id_request', label: 'Id Richiesta', crud: ['select'], format: 'hidden' },
            { name: 'description', label: 'Descrizione', crud: ['select'], format: 'string', tableSort: 1 },
            { name: 'fullname_user', label: 'Creata da', crud: ['select'], format: 'string', tableSort: 2 },
            { name: 'creation', label: 'Creata il', crud: ['select'], format: 'boolean', tableSort: 3 }
        ],
        filters: [],
        addFields: [
            {
                name: 'description', label: 'Descrizione', format: 'string', tableSort: 2, crud: ['select', 'insert'],
                form: { type: 'textarea', name: 'description', placeholder: 'Inserisci la descrizione', isRequired: true, maxLength: 1000, formSort: 2 }
            },
            {
                name: 'id_user', label: 'Operatore', format: 'hidden', crud: ['select', 'insert', 'update'],
                form: {
                    type: 'select', name: 'id_user', isRequired: true, formSort: 1,
                    select: {
                        sourceName: 'users',
                        placeholder: 'Selezionare un operatore',
                        id: 'id',
                        label: 'fullname',
                        filters: [
                            { field: 'active', value: 1, operator: '=' }, // solo operatori attivi
                        ]
                    }
                }
            }
        ]
    }
];

const absenceList = new EntityModel('');
absenceList.model = typeof AbsenceModel;
absenceList.sourceName = 'absences';
absenceList.fields = [
    {
        name: 'id', label: 'ID', format: 'hidden', crud: ['select', 'update'],
        form: { type: 'hidden', name: 'id', isRequired: false, pattern: "^[0-9]*$" }
    },
    {
        name: 'execution', label: 'Data', format: 'date', tableSort: 4, group: 'Quando', crud: ['select', 'insert', 'update'],
        form: { type: 'date', name: 'execution', isRequired: true, formSort: 4 }
    },
    {
        name: 'start_time', label: 'Inizio', format: 'time', tableSort: 5, group: 'Quando', crud: ['select', 'insert', 'update'],
        form: { type: 'time', name: 'start_time', isRequired: false, formSort: 5 }
    },
    {
        name: 'end_time', label: 'Fine', format: 'time', tableSort: 6, group: 'Quando', crud: ['select', 'insert', 'update'],
        form: { type: 'time', name: 'end_time', isRequired: false, formSort: 6 }
    },
    {
        name: 'id_user', label: 'Operatore', format: 'hidden', crud: ['select', 'insert'],
        form: {
            type: 'select', name: 'id_user', isRequired: true, formSort: 1,
            select: {
                sourceName: 'users',
                placeholder: 'Selezionare un operatore',
                id: 'id',
                label: 'fullname',
                filter: true,
                filters: [
                    { field: 'active', value: 1, operator: '=' }, // solo operatori attivi
                    { field: 'admin', value: 0, operator: '=' } // escludo admin
                ]
            }
        }
    },
    { name: 'fullname_user', label: 'Operatore', format: 'string', tableSort: 1, crud: ['select'] },
    {
        name: 'id_absence_type', label: 'Tipo Assenza', format: 'hidden', crud: ['select', 'insert', 'update'],
        form: {
            type: 'select', name: 'id_absence_type', isRequired: true, formSort: 2,
            select: {
                sourceName: 'absences_types',
                placeholder: 'Selezionare una tipologia',
                id: 'id',
                label: 'description',
                filters: [
                    { field: 'active', value: 1, operator: '=' }, // solo tipi attivi
                ]
            }
        }
    },
    { name: 'description_absence_type', label: 'Tipo Assenza', format: 'string', tableSort: 2, crud: ['select'] },
    {
        name: 'justified', label: 'Giustificata', format: 'boolean', tableSort: 3, crud: ['select', 'insert', 'update'],
        form: { type: 'boolean', name: 'justified', isRequired: true, formSort: 3, defaultValue: 1 }
    },
    { name: 'creation', label: 'Creata', format: 'datetime', tableSort: 7, crud: ['select'] },
    { name: 'edit', label: 'Modificata', format: 'datetime', tableSort: 8, crud: ['select'] },
    {
        name: 'active', label: 'Assenza attiva', format: 'boolean', tableSort: 9, crud: ['select', 'update'],
        form: { type: 'boolean', name: 'active', isRequired: false, formSort: 7 }
    },
    { name: 'editable', label: 'Modificabile', format: 'hidden', crud: ['select'] }
];
absenceList.filters = [
    // { field:'active', value:1, operator:'=' }, // solo assenze attive
];
absenceList.options = {
    label: 'Assenze',
    title: 'Elenco assenze',
    detailTitle: 'Assenza',
    // titleClass: '',
    // tableClass: '',
    // responsive: true,
    sorting: true,
    filterAll: true,
    filterHeader: true,
    pagination: true,
    pageSizeOptions: [10, 25, 100, 1000],
    pageSize: 10,
    rowClick: 'info',
    rowActionMenu: true,
    rowActionDetail: false,
    rowActionEdit: true,
    exportTypes: ["xlsx"],
    defaultSortActive: 'execution',
    defaultSortDirection: 'desc'
};

const yearList = new EntityModel('');
yearList.model = typeof YearModel;
yearList.sourceName = 'years';
yearList.fields = [
    { name: 'id', label: 'ID', form: { type: 'hidden', name: 'id', isRequired: false, pattern: "^[0-9]*$" }, format: 'hidden', crud: ['select', 'update'] },
    { name: 'description', label: 'Descrizione', form: null, format: 'string', crud: ['select'], tableSort: 1 },
    { name: 'day_start', label: 'Inizio', form: { type: 'date', name: 'day_start', isRequired: true, formSort: 1 }, format: 'date', crud: ['select', 'insert', 'update'], tableSort: 2 },
    { name: 'day_end', label: 'Fine', form: { type: 'date', name: 'day_end', isRequired: true, formSort: 2 }, format: 'date', crud: ['select', 'insert', 'update'], tableSort: 3 },
    { name: 'current_year', label: 'Anno corrente', form: { type: 'boolean', name: 'current_year', isRequired: false, formSort: 3 }, format: 'boolean', crud: ['select'], tableSort: 4 },
    { name: 'creation', label: 'Creata', format: 'datetime', crud: ['select'], tableSort: 5 },
    // { name: 'active', label: 'Attiva', form: { type: 'boolean', name: 'active', isRequired: false, formSort: 4 }, format: 'boolean', crud: ['select', 'update'], tableSort: 6 },
    { name: 'active', label: 'Attiva', form: null, format: 'boolean', crud: ['select', 'update'], tableSort: 6 },
];
yearList.filters = [
    //{field:'active', value:1, operator:'='} // solo contratti attivi
];
yearList.options = {
    label: 'Anni',
    title: 'Elenco anni',
    detailTitle: 'Anno',
    // titleClass: '',
    // tableClass: '',
    // responsive: true,
    sorting: true,
    filterAll: true,
    filterHeader: true,
    pagination: true,
    pageSizeOptions: [10, 25, 100],
    pageSize: 10,
    rowClick: 'edit',
    rowActionMenu: true,
    rowActionDetail: false,
    rowActionEdit: true,
    defaultSortActive: 'day_start',
    defaultSortDirection: 'asc'
};


let entities: EntityModel[] = [];
entities['networks'] = networkList;
entities['schools'] = schoolList;
entities['activities'] = activityList;
entities['contracts'] = contractList;
entities['students'] = studentList;
entities['users'] = userList;
entities['assistances'] = assistanceList;
entities['assistances_full'] = assistanceFullList;
entities['requests'] = requestList;
entities['absences'] = absenceList;
entities['absences_full'] = absencesFullList;
entities['years'] = yearList;