import { Component, ViewChild } from '@angular/core';
import { StorageService } from './services/storage.service';
import { Router, NavigationEnd } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  smallScreen: boolean = false;

  role: string = 'user';
  // menu = {
  //   'admin': [
  //     { 'routerLink': '/', 'icon': 'home', 'title': 'Home', 'role': 'user' },
  //     { 'routerLink': '/reti', 'icon': 'business', 'title': 'Reti', 'role': 'admin' },
  //     { 'routerLink': '/istituti', 'icon': 'school', 'title': 'Istituti', 'role': 'admin' },
  //     { 'routerLink': '/allievi', 'icon': 'child_care', 'title': 'Allievi', 'role': 'admin' },
  //     { 'routerLink': '/attivita', 'icon': 'edit_calendar', 'title': 'Attività', 'role': 'admin' },
  //     { 'routerLink': '/operatori', 'icon': 'people_alt', 'title': 'Operatori', 'role': 'admin' },
  //     { 'routerLink': '/presenze', 'icon': 'handshake', 'title': 'Presenze', 'role': 'user' },
  //     { 'routerLink': '/assenze', 'icon': 'work_off', 'title': 'Assenze', 'role': 'user' },
  //     { 'routerLink': '/segnalazioni', 'icon': 'playlist_add_check', 'title': 'Segnalazioni', 'role': 'user' },
  //     { 'routerLink': '/upload', 'icon': 'cloud_upload', 'title': 'Upload', 'role': 'admin' },
  //   ],
  //   'user': [
  //     { 'routerLink': '/', 'icon': 'home', 'title': 'Home' },
  //     { 'routerLink': '/presenze', 'icon': 'handshake', 'title': 'Presenze' },
  //     { 'routerLink': '/assenze', 'icon': 'work_off', 'title': 'Assenze' },
  //     { 'routerLink': '/segnalazioni', 'icon': 'playlist_add_check', 'title': 'Segnalazioni' },
  //   ]
  // };
  menu = [
    { 'type': 'item', 'routerLink': '/', 'icon': 'home', 'title': 'Home', 'role': 'user' },
    { 'type': 'item', 'routerLink': '/presenze', 'icon': 'handshake', 'title': 'Presenze', 'role': 'user' },
    { 'type': 'item', 'routerLink': '/assenze', 'icon': 'work_off', 'title': 'Assenze', 'role': 'user', 'hideContract': true },
    { 'type': 'item', 'routerLink': '/segnalazioni', 'icon': 'playlist_add_check', 'title': 'Segnalazioni', 'role': 'user' },
    { 'type': 'item', 'routerLink': '/upload', 'icon': 'cloud_upload', 'title': 'Upload', 'role': 'admin' },
    { 'type': 'item', 'routerLink': '/statistiche', 'icon': 'leaderboard', 'title': 'Statistiche', 'role': 'user' },
    {
      'type': 'dropdown', 'icon': 'dog', 'title': 'Anagrafiche', 'role': 'admin', 'items': [
        { 'type': 'item', 'routerLink': '/reti', 'icon': 'business', 'title': 'Reti', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/istituti', 'icon': 'school', 'title': 'Istituti', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/allievi', 'icon': 'child_care', 'title': 'Allievi', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/attivita', 'icon': 'edit_calendar', 'title': 'Attività', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/operatori', 'icon': 'people_alt', 'title': 'Operatori', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/anni', 'icon': 'refresh', 'title': 'Anni', 'role': 'admin' },
      ]
    },
    {
      'type': 'dropdown', 'icon': 'people_alt', 'title': 'Archivio', 'role': 'admin', 'items': [
        { 'type': 'item', 'routerLink': '/archivio/presenze', 'icon': 'people_alt', 'title': 'Presenze', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/archivio/assenze', 'icon': 'people_alt', 'title': 'Assenze', 'role': 'admin' },
      ]
    },
    {
      'type': 'dropdown', 'icon': 'report', 'title': 'Report', 'role': 'admin', 'items': [
        { 'type': 'item', 'routerLink': '/report/istituto-mese', 'icon': 'grid_on', 'title': 'Istituto mese', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/report/operatore-mese', 'icon': 'grid_on', 'title': 'Operatore mese', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/report/istituto-settimana', 'icon': 'grid_on', 'title': 'Istituto settimana', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/report/operatore-settimana', 'icon': 'grid_on', 'title': 'Operatore settimana', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/report/contratto-settimana', 'icon': 'grid_on', 'title': 'Contratto settimana', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/report/sostituzione-settimana', 'icon': 'grid_on', 'title': 'Sostituzione settimana', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/report/settimane-incomplete', 'icon': 'grid_on', 'title': 'Settimane incomplete', 'role': 'admin' },
      ]
    },
  ];

  title = 'GAss';
  loggedIn: boolean = false;
  termsAndConditions: boolean = false;
  admin: boolean = false;
  principal: boolean = false;
  opened: boolean;
  events: string[] = [];
  username: string = '';
  mobile: boolean = false;
  contract :string;

  constructor(
    private storage: StorageService,
    private router: Router
  ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        // chiude il sidenav quando viene rilevato il cambio di pagina, solo small screen
        if(this.smallScreen) this.sidenav?.close();
        // recupera eventuale parametro "mobile" settato solo da app mobile
        this.mobile = this.storage.getMobile() == 'true' ? true : false;
        // recupera e controlla il ruolo dell'utente
        const token = this.storage.getToken();
        if (token) {
          this.loggedIn = true;
          this.role = this.storage.getRole();
          this.username = this.storage.getUserUsername();
          this.termsAndConditions = this.storage.getUserTermsAndConditions();
          this.contract = this.storage.getUserContract();
          this.menu = this.menu.filter(item => !(item.hideContract != undefined && item.hideContract == true
              && ['Partinta Iva', 'Occasionale'].indexOf(this.contract) > -1)
          );

          if (this.role == 'admin') {
            this.admin = true;
          } else {
            this.admin = false;
          }
          if (this.role == 'principal') {
            this.principal = true;
          } else {
            this.principal = false;
          }
        } else {
          this.loggedIn = false;
        }
      }
    });

    this.checkScreenSize();
    window.addEventListener('resize', () => this.checkScreenSize());
  }

  ngOnInit() {
  }

  checkScreenSize() {
    this.smallScreen = window.innerWidth < 768;
  }
}
