import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, map } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { UserModel } from '../models/user.model';
import { environment } from '../../environment/environment';

declare function mobile_logout(): any;

// server test
// const API_URL = 'https://gass.3cengineering.it:8000/api';
// server prod
//const API_URL = 'https://myitinera.coopitinera.it:8000/api';

// local con proxy
// const API_URL = '/api';

// url contenuta nel file environment
const API_URL = environment.API_URL;

const httpOptions = {
  headers: new HttpHeaders({
    'accept': 'application/json'
  }),
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private router: Router, private http: HttpClient, private storage: StorageService) {}

  checkSession(): Observable<UserModel> {
    try {
      return this.http.get<UserModel>(API_URL + '/me/');
    } catch {
      this.router.navigate(['/login']);
      return null;
    }
  }

  logout() {
    if (this.storage.deleteUser()) {
      try {
        // codice per gestire il logout nativo dell'app mobile
        mobile_logout();
      }
      catch {
        // se va in errore il logout mobile, allora serve il logout web
        this.router.navigate(['/login']);
      }
    }
  }

  expiredPassword() {
    this.router.navigate(['/cambio-password']);
  }

  login(username: string, password: string) {
    const data = new FormData();
    data.append("username", username);
    data.append("password", password);

    return this.http.post<any>(API_URL + '/login/', data, httpOptions);
  }

  refreshToken() {
    const data = new FormData();
    const refreshToken = this.storage.getRefreshToken();
    data.append("refreshToken", refreshToken);

    return this.http.get<any>(API_URL + '/refresh_token/');
  }

  changePassword(username: string, oldPassword: string, newPassword: string) {
    const data = {
      "username": username,
      "old_password": oldPassword, // old password
      "new_password": newPassword // new password
    };

    return this.http.post<any>(API_URL + '/change_password/', data, httpOptions);
  }

  acceptTermsAndConditions() {
    return this.http.get<any>(API_URL + '/accept_terms_and_conditions/', {});
  }
}
